













































































import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IMPORT_TRANSACTIONS } from '../store/financeAccount';
import { ApiResponse } from '@/components/types';
import { ImportTransactions } from '../types';
import DatePicker from '@/components/form/DatePicker.vue';
import BankUseSelect from '../../bank/components/BankUseSelect.vue';
import ConservatorSelect from '../../conservator/components/ConservatorSelect.vue';
import { createTransactionImport } from '@/modules/listOfAssets/model';
import { AuthorizationMixin } from '@/views/mixin';
import AppTable from '@/components/AppTable.vue';
import TanDialog from '@/modules/listOfAssets/components/TanDialog.vue';
import uuid from 'uuid/v4';
import ImportTable from '@/modules/listOfAssets/components/ImportTable.vue';

const FinanceAccount = namespace('listOfAssets/financeAccount');

@Component({
  components: {
    ImportTable,
    TanDialog,
    AppTable,
    BankUseSelect,
    ConservatorSelect,
    DatePicker
  }
})
export default class TransactionImport extends AuthorizationMixin {
  @FinanceAccount.Action(IMPORT_TRANSACTIONS) imports!: (params: ImportTransactions) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;
  loaded: boolean = false;

  transactionImport = createTransactionImport(this.isConservator() ? this.$auth.user().id : null);

  importTransactions() {
    this.error = null;

    return this.imports(this.transactionImport);
  }

  async success() {
    this.loading = false;

    this.transactionImport = createTransactionImport(this.isConservator() ? this.$auth.user().id : null);
  }

  async onError(error: any) {
    this.error = error;
    this.transactionImport.import_id = uuid();
  }

  allowed(date: string) {
    return date < this.transactionImport.to;
  }

  @Watch('to')
  watchTo(to: string) {
    if (to >= this.transactionImport.from) {
      return;
    }

    this.transactionImport.from = to;
  }
}
