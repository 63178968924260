
































import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Import, ImportItem } from '../types';
import DatePicker from '@/components/form/DatePicker.vue';
import BankUseSelect from '../../bank/components/BankUseSelect.vue';
import ConservatorSelect from '../../conservator/components/ConservatorSelect.vue';
import { AuthorizationMixin } from '@/views/mixin';
import AppTable from '@/components/AppTable.vue';
import TanDialog from '@/modules/listOfAssets/components/TanDialog.vue';
import TransactionTanDialog from '@/modules/listOfAssets/components/TransactionTanDialog.vue';
import { FETCH_IMPORT, GET_IMPORTS } from '@/modules/listOfAssets/store/financeAccount';
import { ApiResponse } from '@/components/types';

const FinanceAccount = namespace('listOfAssets/financeAccount');

@Component({
  components: {
    TanDialog,
    TransactionTanDialog,
    AppTable,
    BankUseSelect,
    ConservatorSelect,
    DatePicker
  }
})
export default class TransactionImport extends AuthorizationMixin {
  @Prop({ type: String, required: false }) iban?: string

  @FinanceAccount.Action(FETCH_IMPORT) fetchList!: (id: string) => Promise<ApiResponse<Import<ImportItem[]>>>;
  @FinanceAccount.Getter(GET_IMPORTS) list!: Import[];

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.bank'), value: 'bank' },
      { align: 'left', text: this.$i18n.t('common.bankCode'), value: 'bankCode' },
      { align: 'left', text: this.$i18n.t('common.employee'), value: 'employee' },
      { align: 'left', text: this.$i18n.t('common.state'), value: 'state', sortable: false },
      { align: 'left', text: 'Begonnen', sortable: false },
      { align: 'left', text: 'Abgeschlossen', sortable: false }
    ];
  }

  get items() {
    if (!this.iban) return this.list

    return this.list.filter(i => i.iban === this.iban)
  }

  itemClasses(state: string) {
    if (state === 'started') {
      return 'warning';
    }

    if (state === 'waiting') {
      return 'warning';
    }

    if (state === 'completed') {
      return 'success';
    }

    if (state === 'canceled') {
      return 'error';
    }

    return '';
  }

  created() {
    this.fetchList(this.$auth.user().id)
      .catch((error) => console.error(error));
  }
}
