
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_USE_LIST } from '../store';
import { Bank } from '../types';
import { ApiResponse } from '@/components/types';

const Bank = namespace('bank');

@Component
export default class BankUseSelect extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: String, default: 'id' }) itemValue?: string;

  @Bank.Action(FETCH_USE_LIST) fetch!: () => Promise<ApiResponse<Bank[]>>;

  loading: boolean = false;
  list: Bank[] = [];

  get items() {
    return this.list
      .map((bank) => ({ ...bank, name: `${bank.name} (${bank.bankCode})` }))
      .sort((first, second) => first.name.localeCompare(second.name));
  }

  async created() {
    this.loading = true;
    const { content, error } = await this.fetch();
    this.loading = false;

    if (error || !content) return;

    this.list = content;
  }
}
