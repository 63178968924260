import {
  Accounting,
  Asset,
  BankAccountType,
  CancelTransaction,
  CashAccountType,
  CheckBankAccountBalance,
  CorrectAnnualAccounting,
  CreateContraEntry,
  CreditAccountType,
  ImportSEPAAccount,
  ImportSEPAAccountParams,
  ImportTransactions, InternalAccountType,
  ListNewSEPAAccounts,
  OpenBankAccount,
  OpenCashAccount,
  OpenCreditAccount, OpenInternalAccount,
  RemoveAnnualAccounting,
  RemoveVoucher,
  ReverseTransaction,
  Transaction,
  TransactionType,
  UpdateContraEntry,
  UpdateTransaction,
  WriteAsset,
  WriteTransaction
} from '@/modules/listOfAssets/types';
import uuid from 'uuid/v4';
import { format, subDays } from '@/filter/dateFormat';

export const createOpenBankAccount = (list_of_assets_id: string, type: BankAccountType, finance_account_id: string = uuid()): OpenBankAccount => ({
  finance_account_id,
  list_of_assets_id,
  bank_id: '',
  note: '',
  iban: '',
  bic: '',
  balance: 0,
  opened: format(Date.now(), 'yyyy-MM-dd'),
  type
});

export const createOpenCreditAccount = (list_of_assets_id: string, type: CreditAccountType, finance_account_id: string = uuid()): OpenCreditAccount => ({
  finance_account_id,
  list_of_assets_id,
  note: '',
  credit_institute: '',
  credit_number: '',
  income_producing: true,
  balance: 0,
  opened: format(Date.now(), 'yyyy-MM-dd'),
  type
});

export const createOpenCashAccount = (list_of_assets_id: string, type: CashAccountType, finance_account_id: string = uuid()): OpenCashAccount => ({
  finance_account_id,
  list_of_assets_id,
  note: '',
  balance: 0,
  opened: format(Date.now(), 'yyyy-MM-dd'),
  type
});

export const createOpenInternalAccount = (list_of_assets_id: string, type: InternalAccountType, finance_account_id: string = uuid()): OpenInternalAccount => ({
  finance_account_id,
  list_of_assets_id,
  note: '',
  balance: 0,
  opened: format(Date.now(), 'yyyy-MM-dd'),
  type
});

export const createTransactionImport = (
  employee_id: string,
  bank_id: string = '',
  import_id: string = uuid(),
  to: string = format(Date.now(), 'yyyy-MM-dd'),
  iban?: string,
  finance_account_id?: string
): ImportTransactions => ({
  bank_id,
  import_id,
  employee_id,
  finance_account_id,
  username: '',
  pin: '',
  from: format(subDays(Date.now(), 1), 'yyyy-MM-dd'),
  to,
  iban
});

export const createListNewSEPAAccounts = (): ListNewSEPAAccounts => ({
  username: '',
  pin: '',
  bank_id: ''
});

export const createSEPAAccountImport = (
  employee_id: string,
  account: ImportSEPAAccount,
  opened: string = format(Date.now(), 'yyyy-MM-dd'),
  username = '',
  pin = ''
): ImportSEPAAccountParams => ({
  list_of_assets_id: '',
  employee_id,
  username,
  pin,
  bank_id: account.bank_id,
  iban: account.iban,
  bic: account.bic,
  bank_code: account.blz,
  type: null,
  opened
});

export const createCheckBankAccountBalance = (employee_id: string): CheckBankAccountBalance => ({
  employee_id,
  username: '',
  pin: '',
  bank_id: ''
});

export const createAsset = (list_of_assets_id: string, asset_id: string = uuid()): WriteAsset => ({
  list_of_assets_id,
  asset_id,
  balance: 0,
  transacted: format(Date.now(), 'yyyy-MM-dd'),
  group: 'property',
  note: ''
});

export const createWriteTransaction = (finance_account_id: string, employee_id: string, type: TransactionType, voucher_number: string = ''): WriteTransaction => ({
  finance_account_id,
  employee_id,
  money: 0,
  transacted: format(Date.now(), 'yyyy-MM-dd'),
  type,
  reference: '',
  voucher_number
});

export const createContraEntry = (employee_id: string, transaction: Transaction): CreateContraEntry => ({
  finance_account_id: transaction.financeAccountId,
  target_account_id: '',
  employee_id,
  reference: transaction.reference,
  voucher_number: '',
  transaction_id: transaction.id
});

export const createCancelTransaction = (transaction: Transaction): CancelTransaction => ({
  finance_account_id: transaction.financeAccountId,
  transaction_id: transaction.id
});

export const createRemoveVoucher = (transaction: Transaction): RemoveVoucher => ({
  finance_account_id: transaction.financeAccountId,
  transaction_id: transaction.id
});

export const createReverseTransaction = (transaction: Transaction): ReverseTransaction => ({
  finance_account_id: transaction.financeAccountId,
  transaction_id: transaction.id,
  type: transaction.type
});

export const createUpdateTransaction = ({ id, financeAccountId, voucherNumber, ...transaction }: Transaction): UpdateTransaction => ({
  finance_account_id: financeAccountId,
  transaction_id: id,
  voucher_number: voucherNumber,
  money: transaction.money,
  reference: transaction.reference,
  transacted: transaction.transacted
});

export const createUpdateContraEntry = ({ id, financeAccountId, voucherNumber, reference }: Transaction): UpdateContraEntry => ({
  finance_account_id: financeAccountId,
  transaction_id: id,
  voucher_number: voucherNumber,
  reference
});

export const MapAssetToWriteAsset = ({ id, listOfAssetsId, ...asset }: Asset): WriteAsset => ({
  asset_id: id,
  list_of_assets_id: listOfAssetsId,
  ...asset
});

export const MapAccountingToCorrectCommand = ({ listOfAssetsId, id, start, end, withReceiver }: Accounting): CorrectAnnualAccounting => ({
  accounting_id: id,
  list_of_assets_id: listOfAssetsId,
  start,
  end,
  with_receiver: withReceiver
});

export const MapAccountingToRemoveCommand = ({ listOfAssetsId, id }: Accounting): RemoveAnnualAccounting => ({
  accounting_id: id,
  list_of_assets_id: listOfAssetsId
});
