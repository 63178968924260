

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DatePicker from '@/components/form/DatePicker.vue';
import { namespace } from 'vuex-class';
import { SEND_TRANSACTION_TAN } from '@/modules/listOfAssets/store/financeAccount';
import { ApiResponse } from '@/components/types';
import { Import } from '@/modules/listOfAssets/types';

const Import = namespace('listOfAssets/financeAccount');

@Component({
  components: {
    DatePicker
  }
})
export default class TransactionTanDialog extends Vue {
  @Prop({ type: Object, required: true }) value!: Import;

  @Import.Action(SEND_TRANSACTION_TAN) verifyImport!: (params: { import_id: string, username: string, pin: string, tan: string }) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  tan: string = '';
  username: string = '';
  pin: string = '';

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (!value) {
      setTimeout(() => {
        this.error = null;
        this.tan = '';
        this.username = '';
        this.pin = '';

        // @ts-ignore
        this.$refs.form.reset();
      }, 200);

      return;
    }
  }

  async save() {
    this.error = null;

    return this.verifyImport({
      import_id: this.value.id,
      username: this.username,
      pin: this.pin,
      tan: this.tan
    });
  }
}
